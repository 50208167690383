@charset "utf-8";

$majestic-blue: #18243e;
$majestic-burgandy: #7f283b;
$majestic-beige: #a2835f;
$majestic-magnolia: #f3ebd0;
$majestic-ivory: #f2f1f1;

body {
  font-family: 'Poppins', sans-serif;
  font-weight: 200;
  background-color: $majestic-ivory;
}

em {
  font-style: normal;
  color: $majestic-beige;
}

h1, h2, h3, h4, h5 {
  font-family: 'Playfair Display', serif;
  font-weight: 100;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

.bg-dark {
  background-color: $majestic-blue !important;
}

.logo {
  width: 115px;
  height: 47px;
  background: url(../img/logo.png) no-repeat top left;
  background-size: 115px 47px;
}

.phone-number a, .email a {
  text-decoration: none;
  color: $majestic-ivory;
}

.desktop-contact {
  margin-top: -1rem;
  margin-bottom: -2rem;
  background-color: $majestic-beige;
  z-index: 1000;
  .phone-number a {
    font-size: 1.65rem;
    font-weight: 600;
    color: $majestic-burgandy;
  }
  .email a {
    font-size: 0.75rem;
    font-weight: 600;
    color: $majestic-ivory;
  }
}

nav .dropdown-menu {
  background-color: lighten($majestic-blue,5);
  .nav-link {
    color: rgba(255,255,255,.5) !important;
  }

  .nav-link:hover {
    color: #fff !important;
    background-color: $majestic-blue;
  }
}

.mobile-contact {
  background-color: $majestic-blue;
  .phone-number a {
    font-size: 1.25rem;
    color: $majestic-beige;
    text-decoration: none;
  }
  .addr {
    display: block;
    color: $majestic-ivory;
    font-size: 0.75em;
  }
}

@media (max-width: 991px) {
  .navbar-collapse {
    position: fixed;
    top: 77px;
    right: 0;
    width: 40%;
    z-index: 1000;
    background-color: $majestic-burgandy;
    .nav-link {
      color: $majestic-ivory !important;
    }
    .active {
      color: $majestic-beige;
    }
    ul {
      font-family: 'Playfair Display', serif;
      li {
        padding: 0.5rem 3rem !important;
      }
    }
    .mobile-contact {
      background-color: $majestic-burgandy;
      border-top: 1px solid $majestic-beige;
    }
  }

}

@media (max-width: 768px) {
  .navbar-collapse {
    left: 0;
    width: 100%;
    z-index: 1000;
    .nav-link {
      color: $majestic-ivory !important;
    }
    .active {
      background-color: $majestic-beige;
    }
    ul {
      font-family: 'Playfair Display', serif;
    }
    ul:first-child {
      border-bottom: none;
    }
    li {
      padding: 0.5rem 3rem !important;
      background-color: $majestic-burgandy;
      border-bottom: 1px solid $majestic-beige;
    }
    .mobile-contact {
      background-color: $majestic-blue;
      border-top: none;
    }

  }
}

.carousel {

  .carousel-item {
    margin-bottom: -1rem;
    //max-height: 20rem;
  }

  .carousel-caption {
    bottom: unset;
    top: 5rem;
  }

  @media (max-width: 991px) {
    .carousel-caption {
      top: 3rem;
    }
    h1 {
      font-size: 2rem;
    }
    p {
      font-size: 1rem;
    }
    img {
      width: 10rem;
    }
  }

  @media (max-width: 768px) {
    .carousel-caption {
      top: 0.25rem;
    }
  }

  .carousel-indicators {
    justify-content: unset;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    bottom: 1.5rem;
    li {
      width: 20px;
      height: 20px;
      border-radius: 10px;
      border: 3px solid $majestic-ivory;
      background-color: transparent;
    }
    li.active {
      background-color: $majestic-beige;
    }
  }
}

.jumbotron {
  position: relative;
  margin-top: -3rem;
  background-color: $majestic-ivory;
  padding: 3rem 5rem;
}
@media (max-width: 991px) {
  .jumbotron {
    margin-top: -2rem;
  }
}
@media (max-width: 768px) {
  .jumbotron {
    margin-top: -1rem;
    padding: 3rem 1rem;
  }
}
@media (max-width: 576px) {
  .jumbotron {
    margin-top: 0;
  }
}

.offer {
  background: url(../img/rear-view-mirror.png) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

footer {
  color: $majestic-ivory;
  a {
    color: rgba(255,255,255,.5);
  }
  a:hover {
    color: rgba(255,255,255,.75);
    text-decoration: none;
  }
  .quotation {
    background-color: $majestic-burgandy;
    form {
      input::placeholder {
        color: $majestic-ivory;
      }
      input, textarea, select {
        border: 2px solid $majestic-ivory;
        background-color: $majestic-burgandy;
        color: $majestic-ivory;
        border-radius: 0;
      }
      button {
        background-image: url(../img/submit.png);
        border: none;
        width: 157px;
        height: 40px;
        border-radius: 0;
      }
    }
  }
  .info {
    background-color: $majestic-blue;
  }
  .tail {
    background-color: lighten($majestic-blue,5);
    font-size: 0.75rem;
  }
}


.services {
  color: $majestic-ivory;
  h3 {
    color: $majestic-ivory;
  }
  h4 {
    color: $majestic-blue;
  }

  .row:nth-child(1) {
    .service:nth-child(1) {
        background-image: url("/img/svcs/13.jpg");
    }
    .service:nth-child(2) {
      background-image: url("/img/svcs/1.jpg");
    }
    .service:nth-child(3) {
      background-image: url("/img/svcs/2.jpg");
    }
    .service:nth-child(4) {
      background-image: url("/img/svcs/3.jpg");
    }
    .service:nth-child(5) {
      background-image: url("/img/svcs/4.jpg");
    }
    .service:nth-child(6) {
      background-image: url("/img/svcs/5.jpg");
    }
    .service:nth-child(7) {
      background-image: url("/img/svcs/6.jpg");
    }
    .service:nth-child(8) {
      background-image: url("/img/svcs/7.jpg");
    }
    .service:nth-child(9) {
      background-image: url("/img/svcs/8.jpg");
    }
    .service:nth-child(10) {
      background-image: url("/img/svcs/9.jpg");
    }
    .service:nth-child(11) {
      background-image: url("/img/svcs/10.jpg");
    }
    .service:nth-child(12) {
      background-image: url("/img/svcs/11.jpg");
    }
    .service:nth-child(13) {
      background-image: url("/img/svcs/12.jpg");
    }
    .service:nth-child(14) {
      background-image: url("/img/svcs/13.jpg");
    }
  }

  .col {
    height: 16rem;
    padding: 0;
    background-color: $majestic-beige;
    border-bottom: 1px solid $majestic-ivory;
    border-left: 1px solid $majestic-ivory;
    background-size: cover;
    //background-repeat: no-repeat;
    .cover:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 100, 0.5);
      -moz-transition: background .3s linear;
      -webkit-transition: background .3s linear;
      -o-transition: background .3s linear;
      transition: background .3s linear;
    }
    .cover:hover:before {
      background: none;
    }

    .description {
      padding: 3rem;
      background-color: $majestic-beige;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
    }

  }

  .col {
    @media (hover: hover) {
      .description {
        opacity: 0;
        transition: .5s ease;
      }
    }
    @media (hover: none) {
      background-image: none;
      .cover {
        display: block
      }
      .description {
        opacity: 1;
        transition: .5s ease;
      }
    }
  }

  .col:hover .description {
    opacity: 1;
  }

  .col:first-child {
    border-left: none;
  }
}


.with-service-page-link {
  .row:first-child {
    .col:first-child {
      background-image: none;
      background-color: $majestic-burgandy;
      .description {
        background-color: $majestic-burgandy;
        opacity: 1;
      }
    }
  }
}

